import React, { createContext, useReducer, useEffect } from 'react';
import { useContext } from 'react';
import ConfirmOverlayModal from '../components/modals/ConfirmOverlayModal';
import _ from 'lodash';
import { getUser, logoutUser } from '../utils/api/userAPI';
import { useCookies } from 'react-cookie'
import SmallPopupTop from '../components/modals/SmallPopupTop';
import { setDefaultDataset } from '../utils/api/userAPI';

// Create the initial state
const initialState = {
    userId: null,
    // user: {
    //     id: 'development',
    //     email: null,
    //     name: {
    //         first: null,
    //         last: null,
    //     },
    //     institution: null,
    //     role: 'user',
    //     credits: {
    //         scan: 100,
    //         hrpqct: 100,
    //         dxa: 100,
    //         ufrac: 50,
    //         phenotype: 50,}
    // },
    user: null,
    sortField: 'dateCreated',
    sortDirection: 'desc',
    showConfirmModal: false,
    showLogoutModal: false,
    // error popup message
    errorPopupMessage: null,
};

// Create the reducer function
const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload, errorPopupMessage: null };
        case 'LOGOUT':
            return { ...state, showLogoutModal: true };
        case 'LOGOUT_CONFIRM':
            const { removeCookie, message } = action.payload;
            logoutUser();
            if (removeCookie) {
                removeCookie('jwt', { path: '/' });
            }
            return { ...state, user: null, showLogoutModal: false, errorPopupMessage: message ? message : null };
        case 'LOGOUT_CANCEL':
            return { ...state, showLogoutModal: false };
        case 'SET_ALL_REPORTS':
            // calculate pagination states first
            const maxPages = Math.ceil(Object.keys(action.payload).length / state.itemsPerPage);
            return { ...state, allReports: action.payload, reports: action.payload, maxPages: maxPages };
        case 'SET_REPORTS':
            const newMax = Math.ceil(Object.keys(action.payload).length / state.itemsPerPage);
            return { ...state, reports: action.payload, maxPages: newMax };
        case 'SET_PATIENTS':
            return { ...state, patients: action.payload };
        case 'SET_SEARCH_QUERY':
            const query = action.payload.toLowerCase();
            let newReports = {};
            // filter reports based on search query and save to reports
            // if query is empty, set reports to allReports
            if (query === '') {
                newReports = state.allReports;
            }
            // if query is not empty, filter reports
            // if query is the same as the previous query but with more characters, filter from reports
            else if (query.includes(state.searchQuery)) {
                newReports = _.pickBy(state.reports, (value, key) => {
                    return value.reportName.toLowerCase().includes(query) || value.reportType.toLowerCase().includes(query);
                });
            } else {
                // if query is different, filter from allReports
                newReports = _.pickBy(state.allReports, (value, key) => {
                    return value.reportName.toLowerCase().includes(query) || value.reportType.toLowerCase().includes(query);
                });
            }
            const newMaxPages = Math.ceil(Object.keys(newReports).length / state.itemsPerPage);
            return { ...state, reports: newReports, searchQuery: query, maxPages: newMaxPages };
        case 'SET_SORT_PARAMETERS':
            return {
                ...state,
                sortField: action.payload.field !== null ? action.payload.field : state.sortField,
                sortDirection: action.payload.direction !== null ? action.payload.direction : state.sortDirection
            };
        case 'RESET_DELETE_MODE':
            return {
                ...state, showConfirmModal: false, reportIdToDelete: null, confirmModalMessage: {
                    message: '',
                    subMessage: '',
                }
            };
        case 'SET_CONFIRM_MODAL':
            return {
                ...state,
                showConfirmModal: true,
                reportIdToDelete: action.payload.reportId,
                confirmModalMessage: {
                    message: action.payload.message,
                    subMessage: action.payload.subMessage,
                }
            };
        case "SET_ERROR_POPUP": {
            const message = action.payload ? action.payload : null;
            return {
                ...state,
                errorPopupMessage: message
            };
        }
        case "SET_CURRENT_PAGE": {
            const { page } = action.payload;
            if (page < 1 || page > state.maxPages) {
                return state;
            }
            return {
                ...state,
                currentPage: page,
            };
        }
        case "UPDATE_DEFAULT_DATASET": {
            return {
                ...state,
                user: {
                    ...state.user,
                    defaults: action.payload,
                },
            };
        }
        default:
            return state;
    }
};

// Create the UserContext
const UserContext = createContext();

// Create the UserProvider component
export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [cookies, setCookie, removeCookie] = useCookies(['jwt']);


    const updateUser = async (user) => {
        if (!user) {
            const res = await getUser();
            if (!res.ok) {
                console.error('Error fetching user data:', res);
                return;
            }
            const data = await res.json();
            user = data.user;
        }

        dispatch({ type: 'LOGIN', payload: user });
    };

    const updateDefaultDataset = async (type, datasetId) => {

        const res = await setDefaultDataset(type, datasetId);
        if (!res.ok) {
            throw new Error('Error updating default dataset in the server');
        }
        const data = await res.json();

        dispatch({ type: 'UPDATE_DEFAULT_DATASET', payload: data.defaults });

    };

    const setSortParameters = (field, direction) => {
        dispatch({ type: 'SET_SORT_PARAMETERS', payload: { field, direction } });
    };


    return (
        <UserContext.Provider
            value={{ state, dispatch, updateUser, setSortParameters, updateDefaultDataset }}
        >
            <ConfirmOverlayModal
                message={"Confirm Logout"}
                subMessage={"Are you sure you want to log out?"}
                isOpen={state.showLogoutModal}
                onCancel={() => {
                    dispatch({ type: "LOGOUT_CANCEL" });
                }}
                onConfirm={() => {
                    dispatch({ type: "LOGOUT_CONFIRM", payload: { removeCookie: removeCookie } });
                }}
            />
            {state.errorPopupMessage !== null && <SmallPopupTop
                isShowing={state.errorPopupMessage !== null}
                setShow={(temp) => dispatch({ type: "SET_ERROR_POPUP", payload: null })}
                message={state.errorPopupMessage}
                type={'error'}
                title={'Error'}
            />}
            {children}
        </UserContext.Provider>
    );
};

// Create the useUser hook
export const useUser = () => useContext(UserContext);
