
export const updateUserDetails = async (firstName, lastName, institution) => {
    return await fetch('/api/user/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName, lastName, institution }),
        credentials: 'include',
    });
}

export const getUser = async () => {
    return await fetch('/api/user', {
        method: 'GET',
        credentials: 'include',
    });
}

export const updatePassword = async (currentPassword, newPassword) => {
    return await fetch('/api/user/change-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ currentPassword, newPassword }),
        credentials: 'include',
    });
}

export const updateEmail = async (email, password) => {
    return await fetch('/api/user/change-email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        credentials: 'include',
    });
}

export const logoutUser = async () => {
    return await fetch('/api/user/logout', {
        method: 'GET',
        credentials: 'include',
    });
}

export const deleteUser = async (password) => {
    return await fetch('/api/user/delete', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password }),
        credentials: 'include',
    });
}

export const forgotPassword = async (email) => {
    return await fetch('/api/user/forgot-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    });
}

export const resetPassword = async (password, token) => {
    return await fetch('/api/user/reset-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password, token }),
    });
}


export const setDefaultDataset = async (reportType, datasetId) => {
    return await fetch('/api/user/set-default-dataset', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reportType, datasetId }),
        credentials: 'include',
    });
}

export const getDefaults = async () => {
    return await fetch('/api/user/get-default-datasets', {
        method: 'GET',
        credentials: 'include',
    });
}
