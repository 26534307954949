import React from 'react'
import { FaCheckCircle, FaCircle } from 'react-icons/fa'
import { IoClose } from 'react-icons/io5'

const SelectBox = ({ handleClick, handleDeselect, isSelected, isRequired, children, disabled, allowDeselect }) => {

    return (
        // isRequired ? null : !isSelected ? handleClick : allowDeselect ? handleDeselect : null
        <div onClick={isRequired ? null : disabled ? null : handleClick} className={` ${isRequired ? 'bg-blue-100 border-blue-100' : disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${isSelected ? 'bg-blue-100 border-normative-blue' : disabled ? 'border-gray-100 bg-gray-100' : 'hover:border-slate-300 hover:bg-slate-100'} border-2 rounded-md transition-all relative`}>
            <div className='flex gap-3 px-3 py-1 items-center group/outer'>
                {isSelected ? (
                    <div className="group/inner relative" onClick={(e) => {
                        e.stopPropagation();
                        if (isRequired || disabled) return
                        handleDeselect ? handleDeselect() : handleClick()
                    }}>
                        {(!isRequired && !disabled) && <IoClose className=' text-gray-600 bg-red-300 rounded-full absolute group-hover/inner:opacity-100 opacity-0 transition-all scale-105' />}
                        <FaCheckCircle className={`text-normative-blue ${(!isRequired && !disabled) && 'group-hover/inner:opacity-0'} transition-all`} />
                    </div>
                ) : (
                    <div>
                        <FaCircle className=' text-gray-200' />
                    </div>
                )}
                {children}
            </div>
        </div>
    )
}

export default SelectBox