import React from 'react'

const SelectedOptionsModal = ({ isShowing, selected, total, onDelete, onCancel }) => {
    if (!isShowing) return null
    return (
        <div className='fixed flex items-center bottom-10 border border-slate-600 right-10 bg-normative-dark-panel rounded-md shadow-lg p-3 m-4 gap-3'>
            <span className='text-gray-200'>
                <span className="text-white font-semibold">{selected}</span> of <span className="text-white font-semibold">{total}</span> selected
            </span>
            {/* vertical line */}
            <div className='border-r border-gray-300 h-6 ml-3'></div>

            <div className="flex gap-2">
                <button onClick={onCancel} className='text-white transition-colors hover:bg-gray-500/50 px-2 py-0.5 rounded-md'>Cancel</button>
                <button onClick={onDelete} className='text-red-500 font-medium hover:bg-red-400/20 px-2 py-0.5 rounded-md transition-colors'>Delete</button>
            </div>
        </div>
    )
}

export default SelectedOptionsModal