import React, { createContext, useContext, useReducer } from 'react';
import ConfirmOverlayModal from '../components/modals/ConfirmOverlayModal';
import SmallPopupTop from '../components/modals/SmallPopupTop';
import SelectedOptionsModal from '../components/report/shared/SelectedOptionsModal';

const initialState = {
    confirmModal: {
        isOpen: false,
        message: '',
        subMessage: '',
        children: null,
        setDoNotShowAgain: null,
        onConfirm: () => { },
        onCancel: () => { }
    },
    selectedOptions: {
        isShowing: false,
        selected: [],
        total: 0,
        onDelete: () => { },
        onCancel: () => { }
    },
    smallPopupTop: {
        isShowing: false,
        message: '',
        type: '',
        title: '',
        logText: '',
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'SHOW_CONFIRM_MODAL':
            return {
                ...state,
                confirmModal: {
                    isOpen: true,
                    message: action.payload.message,
                    subMessage: action.payload.subMessage,
                    children: action.payload.children,
                    setDoNotShowAgain: action.payload.setDoNotShowAgain,
                    onConfirm: action.payload.onConfirm,
                    onCancel: action.payload.onCancel
                }
            }
        case 'RESET_CONFIRM_MODAL':
            return {
                ...state,
                confirmModal: {
                    isOpen: false,
                    message: '',
                    subMessage: '',
                    children: null,
                    setDoNotShowAgain: null,
                    onConfirm: () => { },
                    onCancel: () => { }
                }
            }
        case 'SHOW_SELECTED_OPTIONS':
            return {
                ...state,
                selectedOptions: {
                    isShowing: action.payload.selected > 0,
                    selected: action.payload.selected,
                    total: action.payload.total,
                    onDelete: action.payload.onDelete,
                    onCancel: action.payload.onCancel
                }
            }
        case 'SHOW_SMALL_POPUP_TOP':
            return {
                ...state,
                smallPopupTop: {
                    isShowing: true,
                    message: action.payload.message,
                    type: action.payload.type,
                    title: action.payload.title,
                    logText: action.payload.logText
                }
            }
        case 'RESET_OPTIONS': {
            const modalType = action.payload.modalType;
            return {
                ...state,
                [modalType]: {
                    ...initialState[modalType]
                }
            }
        }
        
        default:
            return state;
    }
}

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ModalContext.Provider value={{ state, dispatch }}>
            {children}
            <ConfirmOverlayModal
                isOpen={state.confirmModal.isOpen}
                message={state.confirmModal.message}
                subMessage={state.confirmModal.subMessage}
                setDoNotShowAgain={state.confirmModal.setDoNotShowAgain}
                onCancel={() => {
                    if (state.confirmModal.onCancel) {
                        state.confirmModal.onCancel();
                    }
                    dispatch({ type: "RESET_CONFIRM_MODAL" });
                }}
                onConfirm={() => {
                    if (state.confirmModal.onConfirm) {
                        state.confirmModal.onConfirm();
                    }
                    dispatch({ type: "RESET_CONFIRM_MODAL" });
                }}
            >
                {state.confirmModal.children}
            </ConfirmOverlayModal>
            <SmallPopupTop 
                message={state.smallPopupTop.message}
                isShowing={state.smallPopupTop.isShowing}
                type={state.smallPopupTop.type}
                title={state.smallPopupTop.title}
                logText={state.smallPopupTop.logText}
                setShow={() => {
                    dispatch({ type: 'RESET_OPTIONS', payload: { modalType: 'smallPopupTop' } });
                }}
            />
            <SelectedOptionsModal
                isShowing={state.selectedOptions.isShowing}
                selected={state.selectedOptions.selected}
                total={state.selectedOptions.total}
                onDelete={state.selectedOptions.onDelete}
                onCancel={state.selectedOptions.onCancel}

            />


        </ModalContext.Provider>
    )
}

export const useModal = () => useContext(ModalContext);