import React from 'react';

const ConfirmOverlayModal = ({ message, subMessage, isOpen, onCancel, onConfirm, children, setDoNotShowAgain }) => {
    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    return (
        <>
            {isOpen && (
                <div className="w-full p-5 h-screen bg-black bg-opacity-50 fixed inset-0 flex justify-center items-center hover:cursor-pointer z-50" onClick={onCancel}>
                    <div className="flex flex-col bg-white rounded-md gap-2 p-3 hover:cursor-default md:max-w-screen-lg" onClick={stopPropagation}>
                        <h2 className='font-semibold text-lg text-text-primary'>{message}</h2>
                        <hr />
                        {subMessage && <p className='text-text-secondary-dark'>{subMessage}</p>}
                        {children}
                        <div className="flex justify-between mt-10">
                            <div className='flex items-center gap-1'>
                                {setDoNotShowAgain && <>
                                    <input type="checkbox" id="doNotShowAgain" className=' cursor-pointer' onChange={setDoNotShowAgain} />
                                    <label htmlFor="doNotShowAgain" className="text-text-secondary-reg font-light text-sm cursor-pointer">Do not show this message again</label>
                                </>}
                            </div>
                            <div className="flex gap-3">
                                <button className='px-2 py-0.5 rounded-md bg-slate-100' onClick={onCancel}>Cancel</button>
                                <button className='px-2 py-0.5 rounded-md bg-normative-blue text-white' onClick={onConfirm}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConfirmOverlayModal;