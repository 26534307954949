import React from 'react';
import { Helmet } from 'react-helmet-async';

const PageTitle = ({
  title,
  description = 'Advanced Research Platform to interpret bone scan data and generate reports.',
  canonical = 'https://normative.ca',
}) => {
  const pathname = window.location.pathname;
  const fullUrl = `${canonical}${pathname}`;

  return (
    <Helmet
      defer={false}>
      <title>{title ? `${title} | Normative` : 'Normative'}</title>
      <meta name='application-name' content={title ? `${title} | Normative` : 'Normative'} />
      <meta itemProp='name' content={title ? `${title} | Normative` : 'Normative'} />
      <meta name="description" content={description} />
      <link rel="canonical" href={fullUrl} />

      {/* Open Graph tags */}
      <meta property="og:title" content={title ? `${title} | Normative` : 'Normative'} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:site_name" content="Normative" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default PageTitle;