import React from 'react';
import { Link } from 'react-router-dom';
import HomepageTemplate from './templates/HomepageTemplate';
import GoToButton from '../components/shared/GoToButton';
import PageTitle from '../components/shared/PageTitle';

const NotFound = () => {
    return (
        <HomepageTemplate>
            <PageTitle
                title="Not Found"
                description="The page you're looking for does not exist."
            />
            <div className="flex flex-col md:flex-row w-full h-full justify-center items-center flex-grow gap-6">
                <img src='/images/not-found.svg' alt="Not found" className="w-1/2 md:w-1/3" />
                <div className="flex flex-col gap-3 items-center md:items-start">
                    <h1 className="font-semibold text-5xl lg:text-7xl">404 Not Found</h1>
                    <p className="text-xl">The page you're looking for does not exist.</p>
                    <GoToButton to="/" text="Back to homepage" width={64} />
                </div>
            </div>
        </HomepageTemplate>
    );
};

export default NotFound;