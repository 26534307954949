import React from 'react';
import { IoWarningOutline } from 'react-icons/io5';
import PageTitle from './PageTitle';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className='flex flex-col min-h-screen text-text-primary justify-center items-center p-5 md:p-10 bg-normative-black'>
                    <PageTitle title='Error' />
                    <div className='flex flex-col items-center bg-white py-5 gap-1 px-5 md:px-10rounded-md shadow-lg'>
                        <a href='/' className='text-2xl text-primary mb-3'>
                            <img src="/logo/normative-dark.svg" className="h-8" alt="Normative Logo" />
                        </a>
                        <IoWarningOutline className='text-error text-5xl text-red-500' />
                        <h1 className='text-2xl'>Something went wrong</h1>
                        <p className='text-center text-sm'>An error occurred while trying to render this page. Please try refreshing the page or contact us if the issue persists.</p>
                        <button onClick={() => window.location.reload()} className=' mt-3 py-0.5 px-4 rounded-md border-2 border-normative-blue text-normative-blue hover:bg-blue-300 transition-all'>Reload</button>

                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;