import React from 'react';
import Lottie from 'react-lottie-player';
import animationData from '../../lotties/loading-animation.json';
import PageTitle from './PageTitle';

const LoadingScreen = ({ message }) => {
    // get current url from window
    const currentUrl = window.location.href;
    return (
        <div className='w-full flex flex-col items-center justify-center'>
            <PageTitle 
            title="Loading"
            description={message}
             />
            <h1 className='text-xl text-text-primary animate-fade-in-up'>{message}</h1>
            <Lottie
                loop
                animationData={animationData}
                play
                style={{ width: 200, height: 200 }}
            />
        </div>
    );
};

export default LoadingScreen;