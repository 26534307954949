import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

const Footer = () => {

    const [lastUpdated, setLastUpdated] = useState('');
    const [sinceLastUpdated, setSinceLastUpdated] = useState('');
    const [version, setVersion] = useState('');
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        const fetchLastCommitDate = async () => {
            try {
                const response = await fetch('https://api.github.com/repos/Bonelab/normative-2.0-webapp/commits/production', {
                    headers: {
                        'Authorization': `token ${process.env.REACT_APP_GITHUB_TOKEN}`
                    }
                }).then(res => res.json());
                const lastCommitDate = new Date(response.commit.committer.date);
                const commitSHA = response.sha.substring(0, 7);

                const formattedDate = new Intl.DateTimeFormat('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true
                }).format(lastCommitDate);

                setLastUpdated(formattedDate);

                // Calculate the time since the last commit
                const now = new Date();
                const diffTime = Math.abs(now - lastCommitDate);
                const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
                const diffSeconds = Math.floor((diffTime % (1000 * 60)) / 1000);

                if (diffDays > 0) {
                    setSinceLastUpdated(`${diffDays} day${diffDays > 1 ? 's' : ''} ago`);
                } else if (diffHours > 0) {
                    setSinceLastUpdated(`${diffHours} hour${diffHours > 1 ? 's' : ''} ago`);
                } else if (diffMinutes > 0) {
                    setSinceLastUpdated(`${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago`);
                } else {
                    setSinceLastUpdated(`${diffSeconds} second${diffSeconds > 1 ? 's' : ''} ago`);
                }

                // Update version in this format v1.(commit number).(commit date in this format mmddyy)
                const commitDateFormatted = new Intl.DateTimeFormat('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: '2-digit'
                }).format(lastCommitDate).replace(/\//g, '');
                const version = `v2.${commitSHA}.${commitDateFormatted}`;
                setVersion(version);

                // test fetching version from release instead and console.log the response
                // const resTest = await fetch('https://api.github.com/repos/Bonelab/normative-2.0-webapp/releases', {
                //     headers: {
                //         'Authorization': `token ${process.env.REACT_APP_GITHUB_TOKEN}`
                //     }
                // }).then(res => res.json());

                // console.log(resTest);

            } catch (error) {
                console.error('Error fetching the last commit date:', error);
            }
        };

        fetchLastCommitDate();
    }, []);
    return (
        <div className='bg-normative-dark-panel'>
            {/* Beta disclaimer. TODO: Remove on production */}
            <div className='px-10 py-5 text-sm bg-normative-black text-white text-center'>
                <p className='text-center text-red-600'>This is a beta version of the Normative platform. Please report any issues to <a href='mailto:skboyd@ucalgary.ca' className='text-normative-blue hover:underline'>skboyd@ucalgary.ca</a> or <a href='https://bonelab.ca/' target='_blank' rel='noopener noreferrer' className='text-normative-blue hover:underline'>visit our website</a> for more information.</p>
                <p>Normative is currently undergoing a public testing. Some features may be missing or not working. Thank you for your patience.</p>
                <div className='pt-2'>Last updated: {lastUpdated} <span className='text-sm text-gray-400'>{`${sinceLastUpdated}`}</span></div>
                <div className='text-xs'><span className='text-sm text-gray-400'>{`${version}`}</span></div>
            </div>
            <div className='flex flex-col justify-center p-10 gap-5'>
                <div className='flex flex-col gap-3 md:flex-row justify-between'>
                    <div className='flex flex-col gap-3 md:w-1/2'>
                        <Link className='w-40' to='/'>
                            <img src='/logo/normative-light.svg' alt='Normative Logo' />
                        </Link>
                        <p className='text-white text-sm'>Normative is a research platform developed by the Bone Imaging Laboratory at the University of Calgary to help researchers analyze HR-pQCT & DXA scans with reference data, percentile rankings, and detailed reports.</p>
                        <span className='text-gray-500 text-xs'>
                            <a href='https://legacy.normative.ca' className='text-gray-400 hover:underline'>Click here</a> to use the old version
                        </span>
                    </div>
                    <div className="flex gap-3 md:gap-5 lg:gap-10">
                        <div className='flex flex-col gap-1 text-sm'>
                            <h3 className='text-white md:text-lg font-semibold'>Resources</h3>
                            <Link to='/features' className='text-white hover:underline'>Features</Link>
                            <Link to='/faq' className='text-white hover:underline'>FAQ</Link>
                            <Link to='/documentation' className='text-white hover:underline'>Documentation</Link>
                        </div>
                        <div className='flex flex-col gap-1 text-sm'>
                            <h3 className='text-white md:text-lg font-semibold'>Company</h3>
                            <Link to='/about' className='text-white hover:underline'>About</Link>
                            <a href='https://bonelab.ca/' target='_blank' rel='noopener noreferrer' className='text-white hover:underline'>Bone Imaging Laboratory</a>
                        </div>
                    </div>

                </div>
                <hr className='border-gray-500 md:mt-3' />
                <div className="flex flex-col md:flex-row justify-between">
                    <p className='text-gray-500 text-sm'>© {currentYear} Normative. All rights reserved.</p>
                    <Link to='/terms-of-service' className='text-gray-500 text-sm underline'>Terms of Service</Link>
                </div>
            </div>
        </div>
    )
}

export default Footer