// src/components/NameInput.js
import React, { useState } from 'react';

import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const InputField = ({ name, icon, type, placeholder, setValue, value, autocomplete, blurHandler, error, subComponent }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    return (
        <div className="flex flex-col gap-1 relative h-atuo">
            {name && <label className={`text-xs text-gray-600 mb-1 ${error === 2 && !isFocused && 'animate-shake text-red-600'}`} htmlFor={name}>{name}</label>}
            <div className="relative">
                {icon && <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-400">
                    {icon}
                </span>}
                <input
                    type={(type && !showPassword) ? type : 'text'}
                    id={name}
                    value={value}
                    placeholder={placeholder}
                    autoComplete={autocomplete}
                    onFocus={() => setIsFocused(true)}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={(e) => (blurHandler && blurHandler(e.target.value)) || setIsFocused(false)}
                    className={`${icon ? 'pl-10' : 'pl-4'} ${error === 1 && 'border-normative-blue'} ${error === 2 && 'animate-shake border-red-600'} pr-4 py-2 border w-full border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
                />
                {
                    type === 'password' && <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 cursor-pointer" onClick={togglePassword}>
                        {showPassword ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
                    </span>
                }
            </div>
            {/* {type === 'password' && <div className={`${isFocused ? 'py-0.5' : 'py-0'} transition-all`}>
                {type === 'password' && isFocused && name !== 'Confirm Password' && <PasswordStrengthBar password={value} />}
                {name === 'Confirm Password' && isFocused && (error === 2 ? <span className='text-red-600 text-xs'>Passwords do not match</span> : error === 1 ? <span className='text-green-600 text-xs'>Passwords match</span> : <></>)}
            </div>} */}
            {subComponent && (
                <div className={`top-full left-0 w-full transition-all duration-300 ${isFocused ? 'max-h-56' : 'max-h-0'} overflow-hidden`}>
                    {subComponent}
                </div>
            )}

        </div>
    );
};

export default InputField;